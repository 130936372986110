import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "linked-events-api-has-moved"
    }}>{`Linked Events API has moved`}</h1>
    <p>{`Documentation can now be found at `}<a parentName="p" {...{
        "href": "/apis/linkedevents"
      }}>{`https://dev.hel.fi/apis/linkedevents`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      